import React from "react";
import Beaver from "../assets/images/castori-in-via-di-estinzione-3-640x427.jpg";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import SEO from "../components/seo";

function Biver() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Biver`,
          `CRM`,
          `Franquias`,
          `Vendedores`,
          `Vendas`,
          `Gestão`,
        ]}
        title="Por que Biver?"
      />

      <div className="text-center mx-5 md:mx-24 lg:mx-40 xl:mx-64 my-24 lg:my-32">
        <h1 className="font-bold md:mt-24">A história por trás do castor</h1>
        <h3 className="text-gray my-8">
          Nascemos com o objetivo de engajar o fluxo de vendas dos vendedores e
          também de aperfeiçoar a comunicação das redes de franquias.
        </h3>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 px-6 py-24 text-center md:text-left background-white">
        <div className="lg:ml-4 xl:ml-16">
          <h2 className="font-bold">Afinal, por que Biver?</h2>
          <p className="text-gray mt-8">
            <b>Biver</b> é a pronúncia da palavra em inglês <i>beaver</i>, que
            significa castor.
          </p>
          <p className="text-gray mt-8">
            Assim como <i>The Beatles</i> não é escrito <i>The Beetles</i>, o
            nome surgiu como um trocadilho de letras para facilmente ser
            reconhecida e memorizada, transformando a referência em uma marca
            própria.
          </p>
          <p className="text-gray mt-8">
            E porque o castor? Por que ele é um grande executor. Com o castor,
            aprendemos como construir com segurança, estratégia e equilíbrio.
          </p>
        </div>
        <img src={Beaver} alt="" className="w-full lg:w-auto" />
      </div>

      <InformationContent
        className="mx-5 md:mx-24 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no Biver"
        description="Faça parte das empresas que estão crescendo com o CRM e veja suas vendas aumentarem rapidamente."
      />
    </Layout>
  );
}

export default Biver;
